/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p",
    h3: "h3",
    a: "a",
    span: "span",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "about-the-company"
  }, "About the company"), "\n", React.createElement(_components.p, null, "Our client is a leading ride-hailing and delivery company in Canada. They offer one-stop solution for people who need transportation, hourly rentals or even parcel delivery."), "\n", React.createElement(_components.p, null, "Based in Canada, our client wants to make the experience for riders and drivers smoother and simpler. They want to make moving around the city easy, accessible and eco-friendly."), "\n", React.createElement(_components.p, null, "They believe in giving people the power to make better travel choices that connect them to their city and reduce their car dependency."), "\n", React.createElement(_components.p, null, "With a focus on sustainability and convenience, our client is not just about trips but wants to change the way people move."), "\n", React.createElement(_components.h2, {
    id: "challenges"
  }, "Challenges:"), "\n", React.createElement(_components.p, null, "When our client decided to launch their services in Canada, they had several challenges."), "\n", React.createElement(_components.p, null, "They knew they wanted a ride-hailing system and a delivery platform. But to achieve that they needed the right technology."), "\n", React.createElement(_components.h3, null, "Need for efficient and centralized operations"), "\n", React.createElement(_components.p, null, "Our client wanted a platform that could manage everything ride-hailing and parcel delivery in one place."), "\n", React.createElement(_components.p, null, "They needed a single ", React.createElement(_components.a, {
    href: "/ride-hailing-software/"
  }, "ride-hailing software"), " that could manage driver assignments, bookings, tracking and service requests."), "\n", React.createElement(_components.p, null, "With multiple services in one app, they needed a centralized system to streamline operations without switching between apps or tracking everything separately."), "\n", React.createElement(_components.h3, null, "Customer-friendly booking and payment experience"), "\n", React.createElement(_components.p, null, "They knew customers expect simple, seamless and transparent booking and payment options."), "\n", React.createElement(_components.p, null, "To meet this they wanted to offer easy booking through mobile taxi app and web booking feature."), "\n", React.createElement(_components.p, null, "On top of that, they wanted integrated payment gateways and SMS notifications to keep customers informed at every step whether booking a ride or parcel delivery."), "\n", React.createElement(_components.p, null, "Secure integrated payment gateways and real-time SMS notifications were a must for transparent and smooth transactions."), "\n", React.createElement(_components.h3, null, "Ease of use for drivers and riders"), "\n", React.createElement(_components.p, null, "Finally, they needed a ride-hailing software that both drivers and riders could use. They knew complex systems discourage users and make operations difficult."), "\n", React.createElement(_components.p, null, "To make a mark in the Canadian market they wanted a mobile taxi app that drivers could use to navigate, receive assignments and manage tasks."), "\n", React.createElement(_components.p, null, "Similarly, they needed a simple taxi app for users to book and track their trips or deliveries without confusion."), "\n", React.createElement(_components.h2, {
    id: "solutions"
  }, "Solutions"), "\n", React.createElement(_components.p, null, "Yelowsoft provided a flexible, reliable and easy-to-use software solution for their business."), "\n", React.createElement(_components.p, null, "Each feature was designed to solve their problem of building a sustainable and customer-centric ride-hailing and delivery service."), "\n", React.createElement(_components.h3, null, "All-in-one ride-hailing and delivery software"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "/"
  }, "Yelowsoft"), " provided a ‘super app’ that combined ride-hailing, hourly rentals, and parcel delivery into one app."), "\n", React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 800px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 62.5%; position: relative; bottom: 0; left: 0; background-image: url('data:image/webp;base64,UklGRnwAAABXRUJQVlA4IHAAAACwAwCdASoUAA0APtFUo0uoJKMhsAgBABoJZwDG9CHS/F5JnGYdIAD+8jRoAcBSkmKsFcX9PF9GCJXoqxJiLxZam9s/1TGUkxkgbdHCZ0zEkb/Smj8/IbFYk1+l0n+z4A52zPKlLSlv/jlg9PUEv+AA'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"All-in-one-ride-hailing\"\n        title=\"\"\n        data-src=\"/static/a6063a19d0532921bf20ad52b140dad7/cf465/All-in-one-ride-hailing.webp\"\n        data-srcset=\"/static/a6063a19d0532921bf20ad52b140dad7/a5e6d/All-in-one-ride-hailing.webp 200w,\n/static/a6063a19d0532921bf20ad52b140dad7/2276a/All-in-one-ride-hailing.webp 400w,\n/static/a6063a19d0532921bf20ad52b140dad7/cf465/All-in-one-ride-hailing.webp 800w\"\n        sizes=\"(max-width: 800px) 100vw, 800px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }), "\n", React.createElement(_components.p, null, "This allowed them to manage different types of requests from one platform, which kept operations streamlined."), "\n", React.createElement(_components.p, null, "Admin panel had full control over all services. This made it easy to monitor, assign and track rides and deliveries."), "\n", React.createElement(_components.p, null, "With Yelowsoft’s software, they could offer multiple services and keep everything in one place."), "\n", React.createElement(_components.h3, null, "Integrated booking and payment options"), "\n", React.createElement(_components.p, null, "The software featured a web booking portal, payment gateways, and real-time SMS notifications."), "\n", React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 800px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 62.5%; position: relative; bottom: 0; left: 0; background-image: url('data:image/webp;base64,UklGRlwAAABXRUJQVlA4IFAAAABwAwCdASoUAA0APtFWo0uoJKMhsAgBABoJZQDA3CHgafn9BQAA/uuxOU7+xZdFpq9l+wtSx9mian0T2lEAwrKeQ/eb7+//PLRqqT675oMAAA=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"Integrated-booking-and-payment-options\"\n        title=\"\"\n        data-src=\"/static/ff0a9f044c61b17053dfb8f54b114358/cf465/Integrated-booking-and-payment-options.webp\"\n        data-srcset=\"/static/ff0a9f044c61b17053dfb8f54b114358/a5e6d/Integrated-booking-and-payment-options.webp 200w,\n/static/ff0a9f044c61b17053dfb8f54b114358/2276a/Integrated-booking-and-payment-options.webp 400w,\n/static/ff0a9f044c61b17053dfb8f54b114358/cf465/Integrated-booking-and-payment-options.webp 800w\"\n        sizes=\"(max-width: 800px) 100vw, 800px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }), "\n", React.createElement(_components.p, null, "Customers could book through the app or website, pay securely, and stay updated throughout their journey."), "\n", React.createElement(_components.p, null, "These features built trust and convenience and helped the company to establish a professional service."), "\n", React.createElement(_components.h3, null, "Simple design for ease of use"), "\n", React.createElement(_components.p, null, "Yelowsoft crafted an easy-to-use driver app and passenger app."), "\n", React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 800px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 62.5%; position: relative; bottom: 0; left: 0; background-image: url('data:image/webp;base64,UklGRnAAAABXRUJQVlA4IGQAAAAwAwCdASoUAA0APtFUpEuoJKOhsAgBABoJZwAAWp+iBykAAP7ybK/UGEu2GCGb6HMvQUMN8EkupaUWEagF6k61/Ily+zFuoACxTu5Nmw5/wl+QWLMLlQEC8ax8JSEyDCoPAAAA'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"Simple-design-for-ease-of-use\"\n        title=\"\"\n        data-src=\"/static/b24c824a46c6b39903e56600ed823e96/cf465/Simple-design-for-ease-of-use-1.webp\"\n        data-srcset=\"/static/b24c824a46c6b39903e56600ed823e96/a5e6d/Simple-design-for-ease-of-use-1.webp 200w,\n/static/b24c824a46c6b39903e56600ed823e96/2276a/Simple-design-for-ease-of-use-1.webp 400w,\n/static/b24c824a46c6b39903e56600ed823e96/cf465/Simple-design-for-ease-of-use-1.webp 800w\"\n        sizes=\"(max-width: 800px) 100vw, 800px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }), "\n", React.createElement(_components.p, null, "Drivers could navigate tasks easily while the riders had a smooth booking experience."), "\n", React.createElement(_components.p, null, "This intuitive approach helped the company attract new users and ensure smooth adoption of the platform."), "\n", React.createElement(_components.h2, {
    id: "results"
  }, "Results"), "\n", React.createElement(_components.p, null, "With Yelowsoft’s ride-hailing software, the company achieved:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "50% increase in daily bookings due to easy to use system"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "30% increase in customer satisfaction with app"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "40% increase in driver productivity with the app"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "60% increase in reach and visibility in the market with the multi-service solution"), "\n"), "\n"), "\n", React.createElement(_components.h2, {
    id: "how-does-our-client-achieve-these-results"
  }, "How does our client achieve these results?"), "\n", React.createElement(_components.p, null, "The company effectively utilized Yelowsoft’s ride-hailing software by:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Managing bookings and deliveries from a single platform"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Rolling out an intuitive mobile app for drivers and riders"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Setting up secure integrated payment gateways"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Leveraging real-time SMS notifications for enhanced transparency"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Offering diverse services like ride-hailing and parcel delivery in one app"), "\n"), "\n"), "\n", React.createElement(_components.a, {
    href: "/contact/"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 800px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 29.500000000000004%; position: relative; bottom: 0; left: 0; background-image: url('data:image/webp;base64,UklGRoIAAABXRUJQVlA4IHYAAADwAwCdASoUAAYAPtFWo0uoJKMhsAgBABoJZACdMoMYAEjeCdRleI9AAP7x/BHu0OJfQ8RIqkuj8AOrDguXEEU9Trnf1Ar/e1GIIIVVJ8ODv10z1Z7R7TEJpo/k4L7Nh+fwFcV1/ns0YFOhAt3fBJXIWmpbQAAA'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"find-out-how-yelowsoft-can-help-you-grow\"\n        title=\"\"\n        data-src=\"/static/ba1000b343d94dc89ab1a885d71f7546/cf465/find-out-how-yelowsoft-can-help-you-grow-2.webp\"\n        data-srcset=\"/static/ba1000b343d94dc89ab1a885d71f7546/a5e6d/find-out-how-yelowsoft-can-help-you-grow-2.webp 200w,\n/static/ba1000b343d94dc89ab1a885d71f7546/2276a/find-out-how-yelowsoft-can-help-you-grow-2.webp 400w,\n/static/ba1000b343d94dc89ab1a885d71f7546/cf465/find-out-how-yelowsoft-can-help-you-grow-2.webp 800w\"\n        sizes=\"(max-width: 800px) 100vw, 800px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
